import React, { useState } from "react"
import PropTypes from "prop-types"
import PrimaryLayout from "@src/layouts/primary"
import { UserContext } from "@src/context/user-context"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Form, { FormActions } from "@src/components/form"
import FormFieldEmailAddress from "@src/components/form-field-email"
import FormFieldCreatePassword from "@src/components/form-field-password-create"
import FormFieldVerificationCode from "@src/components/form-field-verification-code"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"

const AccountRecoverPasswordPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const [email, setEmail] = useState(params.get("email"))
  const [success, setSuccess] = useState()
  const [formMessage, setFormMessage] = useState()
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("Reset Password")} />
      <UserContext.Consumer>
        {({ recoverPassword }) => {
          const handleSubmit = ({ email, code, password }) => {
            recoverPassword(email, code, password)
              .then(() => {
                setSuccess(true)
                setFormMessage()
              })
              .catch(error => {
                setSuccess(false)
                setFormMessage(error)
              })
          }
          return success ? (
            <ResponsiveSection variant="card">
              <h1>Password has been reset!</h1>
              <p>
                <Link to="/account/login/">Click here to log in now</Link>
              </p>
            </ResponsiveSection>
          ) : (
            <ResponsiveSection variant="card">
              <h1>Password reset</h1>
              <Form
                name="user-password-reset"
                onSubmit={handleSubmit}
                variant="blue"
              >
                <FormFieldEmailAddress
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  label={`Email address`}
                  defaultValue={email}
                  onChange={setEmail}
                  validation={{
                    required: "Enter your email address to reset password",
                  }}
                />
                <FormFieldVerificationCode
                  id="code"
                  name="code"
                  placeholder="Enter your 6-digit password reset code"
                  label="Enter your password reset code"
                  validation={{
                    required: "Check your email for the password reset code",
                  }}
                />
                <FormFieldCreatePassword
                  id="password"
                  name="password"
                  label="New password"
                  placeholder="Set a new password"
                />
                <FormActions>
                  {formMessage && <p className="form-error">{formMessage}</p>}
                  <Button variant="rounded" type="submit">
                    Reset password
                  </Button>
                  <p>
                    Don&apos;t have a reset code?{" "}
                    <Link
                      to={`/account/recovery${
                        email ? "/?email=" + encodeURIComponent(email) : ""
                      }`}
                    >
                      Get one now
                    </Link>
                  </p>
                  <p>
                    Remembered your password?{" "}
                    <Link
                      to={`/account/login/${
                        email ? "?email=" + encodeURIComponent(email) : ""
                      }`}
                    >
                      Log in
                    </Link>
                  </p>
                </FormActions>
              </Form>
            </ResponsiveSection>
          )
        }}
      </UserContext.Consumer>
    </PrimaryLayout>
  )
}
AccountRecoverPasswordPage.propTypes = {
  location: PropTypes.object,
}
export default AccountRecoverPasswordPage
